import React from "react";
import axios from 'axios';

function ContactUs() {
  const handleSubmit = (event) => {
    event.preventDefault();
    let  message = {
      "email" :event.target[0].value,
      "name" :event.target[1].value,
      "mobile" :event.target[2].value,
      "subject" :event.target[3].value,
      "message" :event.target[4].value,
      
    }
    axios.post('https://id4f34hzse.execute-api.ap-south-1.amazonaws.com/default/contact_us',message,{
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Accept":"*/*" // Specify the desired origin or use "*" for any origin
    }}
    )
      .then(response => {
        // Handle the successful response
        console.log(response);
        document.getElementById('message_input').value=''
        document.getElementById('subject_input').selectedIndex=0
        document.getElementById('name').value=''
        document.getElementById('email').value=''
        document.getElementById('mobile').value=''
      
        
        
      })
      .catch(err => {
        alert(err.message);
      });

  }


  return (
    <section id="connect" className="contact-us">
      <h4>Contact Us for Special Request and Orders</h4>

      <div className="contact-form">
        <form action="#" method="post" onSubmit={handleSubmit}>
          <div className="form-group">
            <input className="input" id="email" placeholder="email" type="email" required />

            <input className="input" id="name" type="text" placeholder="name" required />
          </div>
          <div className="form-group">
            <input className="input" id="mobile" placeholder="mobile" type="tel" required />

            {/* <label htmlFor="subject_input"> Subject</label> */}
            <select
              placeholder="Subject line"
              name="subject"
              id="subject_input"
              required
              defaultValue={0}
            >
              <option >
                Subject line
              </option>
              <option value={1}>I'd like to Bulk Order</option>
              <option value={2}>I'd like to Partner With Your Bussiness</option>
              <option value={3}>I'd like to make a proposal</option>
              <option value={4}>Others</option>
            </select>
          </div>

          <div className="message">
            <label htmlFor="message"></label>
            <textarea
              name="message"
              placeholder="I'd like to chat about"
              id="message_input"
              cols="30"
              rows="5"
              required
            ></textarea>
          </div>
          <div className="submit">
            <input type="submit" value="Send Message" id="form_button" />
          </div>
        </form>
      </div>
    </section>
  );
}

export default ContactUs;
