import React from "react";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import Intro from "./Intro";
import Products from "./Products";
import Navbar from "./Navbar";
function Home() {

  return (
    <div className="container">
      <Navbar />
      <Intro />
      <Products />
      <AboutUs />

      <ContactUs />
      <Footer />
    </div>
  );
}

export default Home;
