import React from "react";
import ios from '../images/app-store-s.jpg';
import and from '../images/play-store.png';

function Footer() {
  return (
    <footer className="footer" id="connect">
      <div className="app-link">
        <a href="/android">
          <img
            src={and}
            alt=""
            loading="lazy"
          />
        </a>
        <a href="/ios">
          <img
            src={ios}
            alt=""
            loading="lazy"
          />
        </a>
      </div>

      <div className="privacy">
      <a href="/terms">Terms and Conditions</a>
      <a href="/privacy">Privacy</a>
      <a href="/refundpolicies">Cancellation/Refund Policies</a>
      </div>
      <div className="company">
        <p>© 2022 - 2023. Parei Foods </p>
      </div>

    </footer>
  );
}

export default Footer;
