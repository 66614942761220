import React, { useEffect, useState } from "react";
import axios from "axios";
import { Outlet } from "react-router-dom";
import logo from "../images/logo_alt.png";
import Cart from "./Cart";
import { useMyContext } from "./Context";
import jwt_decode from "jwt-decode";
function Navbar() {
  const [nav, setnav] = useState(false);
  const [showCart, setshowCart] = useState(false);
  const { cart,setCart,user,setUser } = useMyContext();
  const backGroundShadow = () => {
    if (window.scrollY >= 50) setnav(true);
    else setnav(false);
  };
  function toggleMenu() {
    document.querySelector(".usr-menu").toggleAttribute("hidden");
  }
  function toggle() {
    if (cart.length === 0) return;

    document.querySelector(".cart-container").toggleAttribute("hidden");
    setshowCart(!showCart);
  }
  useEffect(() => {
    const span = document.querySelector(".cart>button>span");
    if (cart.length > 0) {
      span.style.display = "block";
      span.textContent = cart.length;
    } else {
      span.style.display = "none";
    }
  }, [cart]);


  function handlecallback(response) {

    var userObject = jwt_decode(response.credential);
    
    localStorage.setItem("user", JSON.stringify(userObject));
    setUser(JSON.stringify(userObject));

  }

  useEffect(() => {

    /*global google */

    google.accounts.id.initialize({
      client_id:
        "504705801375-r869cqor1f44iikulieoile9cioeccqk.apps.googleusercontent.com",
      callback: handlecallback,
    });
// eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user==='') {
      
      localStorage.setItem("user",user);
      google.accounts.id.prompt();
      google.accounts.id.renderButton(
        document.getElementById("btn-login"),
        {
          theme: "outline", size: "small"
        }
      );
      setCart(JSON.parse('[]'));
      return;

    }
    let user_id
    try
    {
      let user_json=JSON.parse(user);
      user_id = user_json.email;

    }
    catch
    {
      user_id='';
    }
    
    axios
      .get(
        "https://id4f34hzse.execute-api.ap-south-1.amazonaws.com/default/cart",
        {
          params: {
            user_id: user_id,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setCart(response.data);
      })
      .catch((err) => {console.log(err)});


  }, [user,setCart]);

  window.addEventListener("scroll", backGroundShadow);
  return (
    <>
      <header className={nav ? "active" : ""}>
        <img src={logo} alt="parei Foods" />
        <input
          type="checkbox"
          name="nav-toggle"
          id="nav-toggle"
          className="nav-toggle"
        />
        <nav>
          <ul>
            <li>
              <a href="#about">About Us</a>
            </li>
            <li>
              <a href="#products">Products</a>
            </li>
            <li>
              <a href="#connect">Contact Us</a>
            </li>
          </ul>
        </nav>
        <label htmlFor="nav-toggle" className="nav-toggle-label">
          <span></span>
        </label>
        <div className="top-menu ">
          {user === '' ? (
            <div className="login">
              <button
                className="btn-accent"
                id="btn-login"
              >
              </button>
            </div>
          ) : (
            <div>
              <button onClick={() => toggleMenu()} className="btn-accent usr">
                {JSON.parse(user)?.name?.split(" ")[0].substring(0, 1)}
              </button>
              <div className="usr-menu" hidden>
                <ul>
                  <li>
                    <a href="/orders">My Orders</a>
                  </li>
                  <li>
                    <a href="/address">Address</a>
                  </li>
                  <li>
                    <a href="/settings">Setting</a>
                  </li>
                  <li>
                    <a href="/account">Account</a>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        
                        setUser('');
                      }}
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}

          <div onClick={() => toggle()} className="cart">
            <button>
              <span>1</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-cart"
                viewBox="0 0 16 16"
              >
                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
            </button>
          </div>
        </div>
      </header>
      <Cart />
      <Outlet />
    </>
  );
}

export default Navbar;
