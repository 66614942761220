// GlobalHandlersContext.js
import React, { createContext, useContext } from "react";
import { useMyContext } from "./Context";
import axios from "axios";
const GlobalHandlersContext = createContext();

export const GlobalHandlersProvider = ({ children }) => {
  const { user, cart, setCart } = useMyContext();

  //cart item increment and decrement functionality
  const setitemcount = (product_id, number) => {
    let tempcart;

    const index = cart.findIndex(
      (cartItem) => cartItem.product_id === product_id
    );
    if (index !== -1) {
      tempcart = [...cart];
      tempcart[index].count += number;

      if (tempcart[index].count === 0) {
        tempcart.splice(index, 1);
        var query_params = {
          user_id: JSON.parse(user).email,
          product_id: product_id,
        };
        axios
          .delete(
            "https://id4f34hzse.execute-api.ap-south-1.amazonaws.com/default/cart",
            { params: query_params }
          )
          .then((resp) => {
            console.log(resp);
          });
      } else {
        let body = { ...tempcart[index] };
        //console.log(body);
        postcart(body);
      }
    }

    setCart(tempcart);
  };
  const postcart = (body) => {
    let user_email;
    if (user === '') {
      user_email = '';
    } else {
      user_email = JSON.parse(user).email;
    }

    let req_body = { ...body, user_id: user_email, product_id: body.id };

    axios
      .post(
        "https://id4f34hzse.execute-api.ap-south-1.amazonaws.com/default/cart",
        req_body,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Accept: "*/*", // Specify the desired origin or use "*" for any origin
          },
        }
      )
      .then((response) => {
        // Handle the successful response
        console.log(response);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  //add to cart functionality
  const handleClick = (item) => {
    let cartItem;
    let tempcart =  [...cart];

    const index = cart.findIndex((cartItem) => cartItem.id === item.id);
    //if item already exists
    if (index !== -1) {
      tempcart[index].count += 1;
      cartItem = tempcart[index];
      setCart(tempcart);
    } else {
      cartItem = { ...item, count: 1 }; // Spread existing properties and add count
      tempcart =[...cart, cartItem]
      setCart(tempcart);
    }

    postcart(cartItem);
  };

  return (
    <GlobalHandlersContext.Provider value={{ setitemcount, handleClick }}>
      {children}
    </GlobalHandlersContext.Provider>
  );
};

export const useGlobalHandlers = () => {
  return useContext(GlobalHandlersContext);
};
