import React, { createContext, useContext, useState } from 'react';


const MyContext = createContext();
const cartFromLocalStorage = JSON.parse("[]");
const userFromLocalStorage = localStorage.getItem("user") || '';

export const MyContextProvider = ({ children }) => {
    const [cart, setCart] = useState(cartFromLocalStorage);
    
    const [user, setUser] = useState(userFromLocalStorage);
    const [address, SetAddress] = useState(null);

  return (
    <MyContext.Provider value={{ cart, setCart, user, setUser,address,SetAddress }}>
      {children}
    </MyContext.Provider>
  );
};

// Create a custom hook to consume the context
export const useMyContext = () => {
  return useContext(MyContext);
};


