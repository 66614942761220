import React, { useEffect, useState } from "react";
import Product from "./Product";
import TryButton from "./TryButton";
import axios from 'axios';

function Products() {

  const [postData, setPostData] = useState(null);
  useEffect(() => {
    // Make a GET request to a sample API endpoint
    axios.get('https://id4f34hzse.execute-api.ap-south-1.amazonaws.com/default/product')
      .then(response => {
        // Handle the successful response
        console.log(response);
        setPostData(response.data);
      })
      .catch(err => {
        // Handle any errors that occurred during the request
        //setError(err.message);
      });
  }, []);




  return (
    <section className="products-container" id="products">
      <h3>Our most popular products</h3>
      <div className="products">
        
        {
        postData?.map((item) => (
          
          <Product
          key={item.id}
            item={item}
          />
        ))}
      </div>
      <div className="explore-btn">
        <TryButton link={"/products"} text={"Explore Products"} />
      </div>
    </section>
  );
}

export default Products;
