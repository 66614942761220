
import React from 'react';
import TryButton from './TryButton';
const logo = 'https://testproductphotos.s3.ap-south-1.amazonaws.com/delivery_boy_new.webp';


function Intro() {
  return (
    <section className='intro'>
        <h4>Parei Foods</h4>
        <div className='wrapper'>
            <div className='text-box'>
                <h2>Get your Pickles delivered Right to your door</h2>
                <p>Indulge in the exquisite world of flavors with Parei Foods.
                    Elevate your culinary experience and savor the authentic taste of our handpicked ingredients. 
                    Order now and let the zestful journey begin!!!</p>
                <TryButton link={"#products"} text={"Try Now"}/>
                
            </div>
            <div className='delivery-img'>
                <img src={logo} alt="" loading='lazy' fetchpriority="high" />
            </div>

        </div>
    </section>
  )
}

export default Intro
