import React, { useEffect, useState } from "react";
import CartItem from "./CartItem";
import { useMyContext } from "./Context";
function Cart() {
  const { cart } = useMyContext();

  let ans = 0;
  function handleprice() {
    cart.map((item) => (ans += item.price * item.count));
    setPrice(ans);
  }
  const [price, setPrice] = useState(0);
  const [order, setOrder] = useState({});
  //run once
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(()=>
  {
    if(Object.keys(order).length===0) return
    var options = {
      key: "rzp_test_IhrGhZ6kjZw8fu", // Enter the Key ID generated from the Dashboard
      amount: order.orders.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Parei Foods",
      description: "Pickle",
      image: "https://pareifoods.s3.ap-south-1.amazonaws.com/pf.jpg",
      order_id: order.orders.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature);
      },
      prefill: {
        name: "Gaurav Kumar",
        email: "gaurav.kumar@example.com",
        contact: "9000090000",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
     /*global Razorpay */
    var rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    document.getElementById("rzp-button1").onclick = function (e) {
      rzp1.open();
      e.preventDefault();
    };
    document.getElementById("rzp-button1").click();
  },[order])

  //run everytime the app re-renders
  useEffect(() => {
    handleprice();
   
  });


  function checkout() {
    fetch("http://3.110.22.139:4000/orders/" + price)
      .then((response) => response.json())
      .then((result) => setOrder(result))
      .catch((err) => console.log(err));
  }

  return (
    <div className="cart-container" hidden>
      <h3>{}</h3>
      <div>
        {cart.length > 0 &&
          cart.map((cartItem) => (
            <CartItem
              key={cartItem.id}
              cartItem={cartItem}
            />
          ))}
      </div>
      <div className="total-price">
        <h5>Total Price</h5>
        <p>&#8377;{price}</p>
      </div>

      <div className="delivery-address">
        <h5>Delivery Address</h5>
        <p>&#8377;{price}</p>
      </div>

      <div className="checkout-btn">
        <button className="btn-accent" onClick={checkout}>
          Checkout
        </button>
        <button id="rzp-button1">Pay</button>
      </div>
    </div>
  );
}

export default Cart;
