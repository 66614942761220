import React from "react";
import Box from "./Box";
import TryButton from "./TryButton";
const chef ='https://testproductphotos.s3.ap-south-1.amazonaws.com/chef.webp'
const support = 'https://testproductphotos.s3.ap-south-1.amazonaws.com/support.webp'
const delivery = 'https://testproductphotos.s3.ap-south-1.amazonaws.com/delivery_boy_new.webp';

function AboutUs() {
  return (
    <section className="about" id="about">
      <div>
        <h3>About Us</h3>
        <div className="about-text">
          <p>
          <strong> Parei Foods </strong> is a premium pickle company that specializes in producing high-quality, 
delicious pickles made from the freshest ingredients.
          </p>
        </div>
      </div>
      <div className="a-container">
        <Box image={chef} title={"Best in Taste"} text={"Our products are sure to satisfy your "+
  "cravings and leave you coming back for more"}/>
        <Box image={support} title={"Available 24 x 7"} text={"Call or Text. We are available anytime,anywhere and anyplace."} />
        <Box image={delivery} title={"Delivery"} text={"We deliver our products to your location. On Time."}/>
      </div>

      <div className="btn-container">
        <TryButton link={"#products"} text={"Try Now"}/>
      </div>

      
    </section>
  );
}

export default AboutUs;
